<template>
  <v-container fluid fill-height>
    <div class="login-backgroud-img"></div>
    <v-card id="login-card" class="elevation-1 login-card body-2">
      <v-toolbar color="primary" dark flat>
        <v-btn icon link to="/">
          <v-icon>{{ icons.arrowLeft }}</v-icon>
        </v-btn>
        <v-row justify="center">
          <div class="text-h6 mr-6 white--text">パスワードの再設定</div>
        </v-row>
      </v-toolbar>
      <v-card-text v-if="!isComplete" class="pb-1">
        メールアドレスを入力してください。<br>
        パスワード再設定メールを送信します。
        <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent>
          <v-text-field outlined dense name="login" label="メールアドレス" type="text" v-model="email" :counter="255"
            :rules="emailRules" required class="my-1"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-text v-else class="mt-3 pb-1">
        パスワード再設定用のメールを送信しました。<br>
        メールのリンクから再設定を行ってください。<br>
        登録されていないメールアドレスには送信されないため、お問い合わせをお願い致します。
      </v-card-text>
      <div v-if="!isComplete" class="login-btn px-4 pb-4">
        <v-btn block color="primary white--text" :loading="loading" :disabled="!valid || email == ''"
          @click="sendData">送信</v-btn>
      </div>
      <div v-if="!isComplete" class="pa-4">
        <p style="border-top:1px solid gray"></p>
        <p class="text-center text--secondary">初めてご利用の方</p>
        <v-row justify="center">
          <router-link to="/create_account" class="primary--text mb-4">
            アカウントを作成する
          </router-link>
        </v-row>
      </div>
      <div v-else class="pa-4">
        <p style="border-top:1px solid gray"></p>
        <v-row justify="center" class="mr-2;">
          <v-btn link to="/home" text dense id="no-background-hover"
            class="mb-4 mt-2 primary--text text-center">トップページへ</v-btn>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mdiArrowLeft } from "@mdi/js";
export default {
  name: "ResetPassword",
  data: () => ({
    icons: { "arrowLeft": mdiArrowLeft },
    loading: false,
    showPassword: false,
    isNoMatch: false,
    isError: false,
    isComplete: false,
    valid: false,
    emailRules: [
      v => !!v || "メールアドレスは必須項目です。",
      v => (v && v.length <= 255) || "メールアドレスは255文字以内で入力してください。",
      v =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "メールアドレスの形式が正しくありません。",
    ],
    email: "",
  }),
  methods: {
    async sendData() {
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_URL}password/reset`,
          {
            "UserId": this.email,
          },
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.results === "complete") {
          this.isComplete = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        console.log(error);
      }
    },
  },
};
</script>

